import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MarkdownWithHtml from 'react-markdown/with-html'

import styles from './recipeDetails.module.css'
import { content as pageStylesContent } from './pageDetails.module.css'

import Image from './image'
import LinkIcon from './linkIcon'

const PostDetails = ({ post, location }) => {
  const { html, frontmatter } = post

  return (
    <>
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <header className={styles.header}>
            <h1 className={classNames('title', styles.title)}>
              {frontmatter.title}
            </h1>
            {frontmatter.author && (
              <p className={styles.author}>
                From:{' '}
                {frontmatter.author_url ? (
                  <a
                    href={frontmatter.author_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {frontmatter.author}
                  </a>
                ) : (
                  frontmatter.author
                )}
              </p>
            )}
            <div className={styles.share}>
              <p>
                Share
                <span className="visuallyhidden"> this post</span>:
              </p>
              <ul>
                <li>
                  <a
                    href={encodeURI(
                      `https://www.facebook.com/sharer/sharer.php?u=${location.href}`
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkIcon label="Facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href={encodeURI(
                      `https://twitter.com/intent/tweet?text=${frontmatter.title}&url=${location.href}&via=kriswines`
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkIcon label="Twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href={encodeURI(
                      `https://pinterest.com/pin/create/button/?url=${
                        location.href
                      }&media=${frontmatter.featured_image &&
                        location.origin +
                          frontmatter.featured_image.src
                            .publicURL}&description=${frontmatter.title}`
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkIcon label="Pinterest" />
                  </a>
                </li>
                <li>
                  <a
                    href={encodeURI(
                      `mailto:?subject=${frontmatter.title}&body=${frontmatter.title}:\n\n ${location.href}`
                    )}
                  >
                    <LinkIcon label="Email" />
                  </a>
                </li>
              </ul>
            </div>
          </header>
          <div className={styles.section}>
            <div className={styles.postGrid}>
              {frontmatter.featured_image && (
                <div className={styles.image}>
                  <Image
                    node={frontmatter.featured_image.src}
                    alt={frontmatter.featured_image.alt}
                    className="framed-image"
                  />
                </div>
              )}
              <div tabIndex={0}>
                <MarkdownWithHtml
                  source={html}
                  escapeHtml={false}
                  className={pageStylesContent}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

PostDetails.propTypes = {
  post: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default PostDetails
