import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import PostDetails from '../components/postDetails'

export default ({ data, location }) => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark

  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        seoTitle={frontmatter.seo_title}
        description={frontmatter.seo_description}
      />
      <PostDetails post={markdownRemark} location={location} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        seo_title
        seo_description
        author
        author_url
        featured_image {
          src {
            publicURL
            childImageSharp {
              fluid(maxWidth: 640) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
      }
    }
  }
`
